export const initDropdown = (parentElement) => {
  const dropdown = parentElement.querySelector(".dropdown");
  parentElement.addEventListener("click", (e) => {
    if (window.matchMedia("only screen and (max-width: 768px)").matches) {
      dropdown.classList.add("open");
      e.stopPropagation();
    }
  });
  document.addEventListener(
    "click",
    (e) => {
      if (!dropdown.contains(e.target)) {
        dropdown.classList.remove("open");
      }
    },
    { passive: true }
  );
};
