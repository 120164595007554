import { initDropdown } from "./dropdown";

let currentStep = 0;
const files = {
  1: null,
  2: null,
};
let currentEmail = null;

const setStep = (step) => {
  if (step === currentStep) return;

  const currentSection = document.getElementById(`step-${currentStep}`);
  const newSection = document.getElementById(`step-${step}`);
  if (!currentSection || !newSection) return;
  currentSection.classList.remove("show");
  newSection.classList.add("show");
  currentStep = step;

  switch (step) {
    case 0:
    case 1:
      files[1] = null;
    case 2:
      files[2] = null;
    case 3:
      currentEmail = null;
      break;
    case 4:
      submitCard();
  }
};

const submitCard = () => {
  const section3 = document.getElementById("step-4");
  section3.classList.add("loading");
  section3.classList.remove("done");
  section3.classList.remove("error");

  const formData = new FormData();
  formData.append("email", currentEmail);
  formData.append("front_image", files[1]);
  formData.append("back_image", files[2]);

  return fetch("/api/users", {
    method: "POST",
    body: formData,
  })
    .then((Response) => {
      if (Response.ok) section3.classList.add("done");
      else throw new Error(Response);
    })
    .catch(() => {
      section3.classList.add("error");
    })
    .finally(() => {
      section3.classList.remove("loading");
    });
};

const initDropzone = (dropzoneId, step) => {
  const dropzone = document.getElementById(dropzoneId);
  const input = dropzone.querySelector(".file-input");

  const setFile = (file) => {
    files[step] = null;
    if (file?.type?.includes?.("image")) {
      files[step] = file;
      setStep(step + 1);
    } else {
      dropzone.classList.add("error");
      setTimeout(() => {
        dropzone.classList.remove("error");
      }, 3000);
    }
  };

  dropzone.addEventListener("click", () => {
    input.click();
  });

  input.addEventListener("change", (e) => {
    setFile(input.files.item(0));
  });

  dropzone.addEventListener("drop", (e) => {
    e.preventDefault();
    dropzone.classList.remove("drag-hover");
    if (e.dataTransfer.items) {
      if (e.dataTransfer.items[0] && e.dataTransfer.items[0].kind === "file") {
        setFile(e.dataTransfer.items[0].getAsFile());
      }
    } else if (e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  });

  dropzone.addEventListener("dragenter", () => {
    dropzone.classList.add("drag-hover");
  });

  dropzone.addEventListener(
    "dragover",
    (event) => {
      event.preventDefault();
    },
    false
  );

  dropzone.addEventListener("dragexit", () => {
    dropzone.classList.remove("drag-hover");
  });

  dropzone.addEventListener("dragover", (e) => {
    e.preventDefault();
  });
};

const initStepOne = () => {
  const startButton = document.getElementById("start-button");
  startButton.addEventListener("click", () => {
    setStep(1);
  });
};

const initMail = () => {
  const inputContainer = document.getElementById("email-input-container");
  const input = inputContainer.querySelector("input");
  const button = inputContainer.querySelector("button.button");

  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const validateEmail = (email) => {
    return re.test(String(email).toLowerCase());
  };

  const setEmailError = (state = true) => {
    if (state) inputContainer.classList.add("invalid");
    else inputContainer.classList.remove("invalid");
  };

  const submit = () => {
    if (validateEmail(input.value)) {
      currentEmail = input.value;
      setStep(4);
    } else {
      setEmailError();
    }
  };

  input.addEventListener("keypress", (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      submit();
    } else {
      setEmailError(false);
    }
  });

  button.addEventListener("click", () => {
    submit();
  });
};

const initFinal = () => {
  const button1 = document.getElementById("try-again-success");
  const button2 = document.getElementById("try-again-error");
  const onTryAgain = () => {
    setStep(0);
  };
  button1.addEventListener("click", onTryAgain);
  button2.addEventListener("click", onTryAgain);
};

const main = () => {
  initDropdown(document.querySelector(".links"));
  initStepOne();
  initDropzone("dropzone", 1);
  initDropzone("dropzone2", 2);
  initMail();
  initFinal();
};

if (document.readyState === "interactive") {
  main();
} else {
  window.addEventListener("DOMContentLoaded", (event) => {
    main();
  });
}
